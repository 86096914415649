import { Attr } from './index'

export const raw: Attr.Definitions = {
  xlinkHref: 'xlink:href',
  xlinkShow: 'xlink:show',
  xlinkRole: 'xlink:role',
  xlinkType: 'xlink:type',
  xlinkArcrole: 'xlink:arcrole',
  xlinkTitle: 'xlink:title',
  xlinkActuate: 'xlink:actuate',
  xmlSpace: 'xml:space',
  xmlBase: 'xml:base',
  xmlLang: 'xml:lang',
  preserveAspectRatio: 'preserveAspectRatio',
  requiredExtension: 'requiredExtension',
  requiredFeatures: 'requiredFeatures',
  systemLanguage: 'systemLanguage',
  externalResourcesRequired: 'externalResourceRequired',
}
